@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /* Lobster Font 'Lobster', 'Helvetica' */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); /* Open Sans Font 'Open Sans', sans-serif */

.body {
    height: 125px;
    width: 100%;
    background: rgb(94, 94, 94);
    margin-top: 0px;
    display: flex;
    padding-top: 0px;
    border-top: 1px solid black;
    margin-bottom: 0px;
}

.footer-holder {
    display: flex;
    text-align: center;
    align-items: center;
    width: 100%;
}

.copyright {
    display: flex;
    color: white;
    font-family: 'Open Sans', sans-serif;
    justify-content: center;
    align-items: center;
    width: 100%;
}