@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /* Lobster Font 'Lobster', 'Helvetica' */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); /* Open Sans Font 'Open Sans', sans-serif */

.body {
    background: rgb(255, 249, 247);
}

.wrapper {
    display: flex;
    justify-content: center;
    max-width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    background: rgb(255, 249, 247);
}

.header-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

header {
    color: black;
    font-family: 'Lobster', 'Helvetica';
    display: flex;
    font-size: 50px;
    text-decoration: underline;
    margin-top: 10px;
}

h4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    text-decoration: underline;
    padding-top: 15px;
}

.menu-wrapper {
    display: block;
    justify-content: center;
    padding-top: 20px;
    columns: 2 400px;
    column-gap: 40px;
}

.item {
    height: 125px;
    width: 100%;
    display: table;
    break-inside: avoid-column; 
    padding-top: 24px;
    padding-bottom: 24px;
}
.name-price {
    display: flex;
    text-align: center; 
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    float: left;
    height: 20px;
    overflow-wrap: break-word;
    flex-direction: row;
    justify-content: space-between;
}

.item-name {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    overflow-wrap: break-word;
    font-size: 16px;
}

.price {
    margin-top: 0;
    line-height: 1;
    font-weight: 400;
    overflow-wrap: break-word;
    display: block;
    max-width: 25%;
    font-size: 16px;
}

.description {
    margin-top: 0;
    margin-bottom: 0;
    overflow-wrap: break-word;
    display: block;
    box-sizing: inherit;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
}


#item-name {
    font-size: 20px;
    font-family: 'Open Sans', arial, sans-serif;
}

#price-styling {
    font-size: 20px;
    font-family: 'Open Sans', arial, sans-serif;
}

@media screen and (max-width:889px) {
    .item {
        padding-top: 10px;
        margin-bottom: 10px;
    }
    .menu-wrapper {
        columns: 2 300px;
    }
    .wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    h4 {
        text-align: center;
        padding-top: 20px;
    }
}

@media screen and (max-width:1089px) {
    .item {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0px;
        padding-right: 0px;
    }
}