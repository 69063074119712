@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /* Lobster Font 'Lobster', 'Helvetica' */
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
/* Quicksand Font 'Quicksand' */

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body  {
    background: rgb(245, 241, 239);
}

.hours-list {
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
}

.card-1-text {
    color: white;
    font-size: 20px;
    vertical-align: middle;
    padding-top: 10px;
}

.card a {
    text-decoration: none;
    color: white;
}

.card a:hover {
    color: rgb(255, 221, 129);
    transition: all 0.3s ease-in-out;
}

.location-text {
    color: white;
    font-size: 20px;
    padding-top: 10px;
    font-family: 'Quicksand', sans-serif;
}

.locations-list {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Quicksand', 'Helvetica';
}

.card li {
    color: white;
    text-decoration: none;
    list-style-type: none;
    text-decoration: underline;
    font-weight: bold;
}

.container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    margin-bottom: 100px;
}

.card {
    background: rgb(77, 77, 77);
    width: 350px;
    height: 350px;
    margin: 10px;
    border-top-right-radius: 20px;
    border: 2px solid black;
    text-align: center;
}

.card h1 {
    background-color: rgb(255, 255, 255);
    border-top-right-radius: 20px;
    padding-bottom: 5px;
    text-decoration: underline;
    font-family: 'Lobster', 'Helvetica';
    border-bottom: 2px solid black;
}

@media  screen and (max-width: 750px) {
    .container {
        display: flex;
        flex-wrap: wrap;
    }
    .hours-open {
        font-size: 18px;
    }
}
