@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /* Lobster Font 'Lobster', 'Helvetica' */

.body {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    background: white;
    border-bottom: 2px solid black;
}

.image {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
}

.image-holder {
    display: flex;
    height: 300px;
    width: 1080px;
    justify-content: center;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.507), rgba(255, 255, 255, 1)), url('../../../public/images/restaurantbackground.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 1px solid black;
}

.text {
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    font-family: 'Lobster', 'Helvetica';
    font-size: 100px;
    text-decoration: underline;
}

@media  screen and (max-width: 750px) {
    .text {
        font-size: 50px;
    }
    .image-holder {
        height: 200px;
        width: 720px;
        border-bottom: none;
    }
}

@media screen and (min-width: 751px) {
    .image-holder {
        border-bottom: none;
    }
    .body {
        border-bottom: 2px solid black;
    }
}