@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /* Lobster Font 'Lobster', 'Helvetica' */

.navbar {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border-bottom: 1px solid #2b2929;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-container {
    margin-left: 20px;
    padding-top: 3px;
    font-family: 'Lobster', 'Helvetica';
    font-size: 25px;
}

.logo-container span {
    font-weight: 500;
    cursor: pointer;
}

.logo-container img {
    width: 45px;
    height: 45px;
    width: auto;
    border-radius: 50%;
}

.links-container {
    margin-right: 20px;
    display: flex;
    justify-content: space-evenly;
    text-decoration: none;
    min-width: 150px; /*Turn this back to 200px when responsiveness is added*/
}

.nav-links {
    display: flex;
    justify-content: space-evenly;
    text-decoration: none;
    width: 200px;
}

.links-container a{
    color: #2b2929;
    font-family: 'Lobster', 'Helvetica';
    font-size: 20px;
    text-decoration: none;
}

.links-container a:hover {
    color: rgb(255, 221, 129);
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
}

.menu-button {
    background-color: #ffffff;
    color: #2b2929;
    border: none;
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
}
/* Hides the menu button until display is at a min width of 500px */
@media only screen and (min-width:500px) {
    .menu-button {
        display: none;
        margin-right: 20px;
    }
}

@media only screen and (max-width:650px) {
    .links-container {
        display: flex;
        align-items: center;
    }
    .links-container a {
        display: flex;
    }
    
    .links-container-mobile  {
        display: inline-block;
        position: absolute;
        height: 200px;
        width: 100%;
        top: 0px;
        left: 0px;
        transition-duration: 0.6s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        background: rgba(255, 255, 255, 0.9);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-top: 50px;
        border-bottom: 1px solid black;
        text-align: center;
        padding-left: 110px;
        padding-right: 110px;
        font-size: 20px;
        font-family: 'Lobster', 'Helvetica';
        color: #2b2929;
    }

    .links-container-mobile a {
        display: flex;
        flex-direction: column;
        color: #2b2929;
        font-size: 30px;
        padding-top: 15px;
        text-decoration: none;
        text-align: center;
        border-bottom: 1px solid black;
    }

    .links-container-mobile a:hover {
        color: rgb(255, 221, 129);
        border-bottom: 1px solid rgb(255, 221, 129);;
        transition: all 0.2s ease-in-out;
    }

    .menu-button {
        display: none; /*Change back to display flex when responsiveness is added*/
        margin-right: 20px;
        align-items: center;
        color: black;
    }

    .links-container a:hover {
        text-decoration: underline;
        
    }
}